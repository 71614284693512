<template>
  <div class="p-4 md:p-8 content">
    <h1
      v-if="page"
      class="text-xl md:text-huge font-bold md:leading-none max-w-4xl"
      v-html="title"
    ></h1>
    <div
      class="text-xl mt-12 max-w-4xl p-spacing content"
      v-html="page.content"
      @click="handleClick"
    />
  </div>
</template>

<script>
export default {
  name: "Content",
  props: ["content"],
  computed: {
    page() {
      return this.$props.content.subpages.find(
        (x) => x.slug === this.$route.params.contentSlug
      )
    },
    title() {
      return this.page.display_title ? this.page.display_title : this.page.title
    },
  },
  methods: {
    handleClick(e) {
      if (e.target.matches("li.panzoom")) {
        this.$emit(
          "zoom",
          e.target.querySelector("img").getAttribute("data-original")
        )
      }
      if (e.target.matches(".read-more-button")) {
        e.target.previousElementSibling.classList.remove("hidden")
        e.target.classList.remove("inline-flex")
        e.target.classList.add("hidden")
        e.target.nextElementSibling.classList.remove("hidden")
        e.target.nextElementSibling.classList.add("inline-flex")
      }
      if (e.target.matches(".read-less-button")) {
        e.target.previousElementSibling.classList.remove("hidden")
        e.target.previousElementSibling.classList.add("inline-flex")
        e.target.previousElementSibling.previousElementSibling.classList.remove(
          "inline-flex"
        )
        e.target.previousElementSibling.previousElementSibling.classList.add(
          "hidden"
        )
        e.target.classList.add("hidden")
      }
    },
  },
}
</script>

<style scoped>
.content {
  >>> img {
    @apply shadow-lg cursor-pointer transition-all duration-500;

    &:hover {
      @apply shadow-2xl;
    }

    &.zoomed {
      @apply fixed inset-0 bg-white;
      z-index: 1000;
    }
  }

  >>> .panzoom {
    cursor: zoom-in;
  }
  >>> a.button {
    @apply py-2 px-8 my-3 rounded-full border text-xl text-center;
    display: inline-block;

    &:hover {
      text-decoration: none;
    }
  }
}
</style>
